<template>
  <div class="wa--farms-search">
    <h1 class="wind--title">
      检索水电场
    </h1>
    <cv-definition-tooltip
      alignment="start"
      direction="bottom"
      definition="水电厂名称（调度名称），所属集团，所属流域，投资运营商。"
      term="可搜索范围包括...">
    </cv-definition-tooltip>
    <cv-search
      placeholder="请输入关键词..."
      clear-aria-label="清空"
      :value="keyword"
      v-model="keyword"></cv-search>
    <div class="bx--form-item">
      <cv-accordion>
        <cv-accordion-item>
          <template v-slot:title>
            更多检索选项
          </template>
          <template v-slot:content>
            <cv-dropdown
              inline
              label="审核状态"
              v-model="searchFilters.status">
              <cv-dropdown-item v-for="item in statusItems" :key="item.label" :value="item.value">
                {{ item.label }}
              </cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
              inline
              label="对标就绪"
              v-model="searchFilters.assessment_ready">
              <cv-dropdown-item v-for="item in assessmentReadyItems" :key="item.label" :value="item.value">
                {{ item.label }}
              </cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
              inline
              label="参与对标"
              v-model="searchFilters.assessment_participation">
              <cv-dropdown-item v-for="item in assessmentParticipationItems" :key="item.label" :value="item.value">
                {{ item.label }}
              </cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
              inline
              label="风场类型"
              v-model="searchFilters.farm_type">
              <cv-dropdown-item v-for="item in farmTypeItems" :key="item.label" :value="item.value">
                {{ item.label }}
              </cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
              inline
              label="机组类型"
              v-model="searchFilters.turbine_type">
              <cv-dropdown-item v-for="item in turbineTypeItems" :key="item.label" :value="item.value">
                {{ item.label }}
              </cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
              inline
              label="调节性能"
              v-model="searchFilters.performance_adjustment">
              <cv-dropdown-item v-for="item in performanceAdjustmentItems" :key="item.label" :value="item.value">
                {{ item.label }}
              </cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
              inline
              label="大坝形式"
              v-model="searchFilters.dam_type">
              <cv-dropdown-item v-for="item in damTypeItems" :key="item.label" :value="item.value">
                {{ item.label }}
              </cv-dropdown-item>
            </cv-dropdown>
          </template>
        </cv-accordion-item>
      </cv-accordion>
    </div>
    <div class="bx--form-item">
      <cv-button
        kind="primary"
        @click="search">
        检索
      </cv-button>
    </div>
    <h3 class="wind--title" v-if="farms.length == 0 && !loading">
      未找到相应风场
    </h3>
    <cv-data-table-skeleton v-if="loading"></cv-data-table-skeleton>
    <cv-data-table
      v-if="farms.length > 0 && !loading"
      :columns="columns"
      :has-expanded-all="false">
      <template v-slot:data>
        <cv-data-table-row
          v-for="(farm, rowIndex) in displayedFarms"
          :key="farm._id"
          :expanded="rowIndex === rowExpanded"
          pagination>
          <cv-data-table-cell>
            <span :title="farm.name" class="bx--text-truncate--end">{{ farm.name }}</span>
          </cv-data-table-cell>
          <cv-data-table-cell>
            {{ farm.area }}
          </cv-data-table-cell>
          <cv-data-table-cell>
            <cv-tag
              :kind="farm.status == 'admitted' ? 'green' : 'gray'"
              :label="farm.status == 'admitted' ? '通过' : '未通过'"></cv-tag>
          </cv-data-table-cell>
          <cv-data-table-cell>
            <cv-tag
              :kind="farm.assessment_participation ? 'cyan' : 'gray'"
              :label="farm.assessment_participation ? '参与' : '不参与'"></cv-tag>
            <cv-tag
              :kind="farm.assessment_ready ? 'green' : 'gray'"
              :label="farm.assessment_ready ? '就绪' : '未就绪'"></cv-tag>
          </cv-data-table-cell>
          <cv-data-table-cell>
            {{ farm.rated_power }} 千千瓦
          </cv-data-table-cell>
          <cv-data-table-cell>
            {{ farm.rated_flow_rate }} 立方米/秒
          </cv-data-table-cell>
          <cv-data-table-cell>
            <cv-overflow-menu flip-menu>
              <cv-overflow-menu-item
                @click="openQuickActions(farm)">
                关键数据
              </cv-overflow-menu-item>
              <cv-overflow-menu-item
                @click="openBinding(farm)">
                绑定用户
              </cv-overflow-menu-item>
              <cv-overflow-menu-item
                disabled>
                编辑信息
              </cv-overflow-menu-item>
              <cv-overflow-menu-item
                @click="navigateNewTab(`/farms/${farm._id}/historical-data`)">
                历史数据
              </cv-overflow-menu-item>
              <cv-overflow-menu-item
                danger
                @click="deleteFarm(farm)">
                删除风场
              </cv-overflow-menu-item>
            </cv-overflow-menu>
          </cv-data-table-cell>
          <template slot="expandedContent">
            <span>
              {{ farm._id }}
            </span>
            <hr class="wind--divider wind--divider--subtle" />
            <span>
              a = {{ numbersHandleNullInText(farm.key_var.native_power_coefficients.a) }}, b = {{ numbersHandleNullInText(farm.key_var.native_power_coefficients.b) }}, c = {{ numbersHandleNullInText(farm.key_var.native_power_coefficients.c) }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              额定流量：{{ farm.rated_flow_rate }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              额定功率：{{ farm.rated_power }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              设计正常高水位：{{ farm.design_normal_high_water_level }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              设计死水位：{{ farm.design_dead_water_level }}
            </span>
            <hr class="wind--divider wind--divider--subtle" />
            <span>
              风场类型：{{ selectionsValueToLabel(farm.farm_type, farmTypes) }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              机组类型：{{ selectionsValueToLabel(farm.turbine_type, turbineTypes) }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              调节性能：{{ selectionsValueToLabel(farm.performance_adjustment, performanceAdjustments) }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              机组类型：{{ selectionsValueToLabel(farm.dam_type, damTypes) }}
            </span>
            <hr class="wind--divider wind--divider--subtle" />
            <span>
              机组台数：{{ farm.hydropower_unit_count }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              引水长度：{{ farm.diversion }} 千米
            </span>
            <span class="wind--text-divider"></span>
            <span>
              总装机容量：{{ farm.total_capacity }} 万千瓦
            </span>
            <hr class="wind--divider wind--divider--subtle" />
            <span>
              项目设计院：{{ farm.project_design_institute }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              水轮机制造商：{{ farm.turbine_manufacturer }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              发电机制造商：{{ farm.generator_manufacturer }}
            </span>
            <hr class="wind--divider wind--divider--subtle" />
            <span>
              电厂投产时间：{{ farm.manufacture_date }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              大坝注册时间：{{ farm.dam_registration_date }}
            </span>
          </template>
        </cv-data-table-row>
      </template>
    </cv-data-table>
    <cv-pagination
      backward-text="上一页"
      forward-text="下一页"
      :forwards-button-disabled="farms.length == 0 || this.noMore"
      page-number-label="页数："
      page-sizes-label="显示项数目："
      :page-sizes="[10, 20, 50]"
      :page="page"
      @change="pageChange"
      >
      <template v-slot:range-text>
        {{ skip + 1 }}-{{ skip + displayedFarms.length }}
      </template>
      <template v-slot:of-n-pages>
        第 {{ page }} 页
      </template>
    </cv-pagination>
    <wa-scope
      v-slot="{ dataCopy }"
      ref="quickActionsScope">
      <validation-observer v-slot="{ handleSubmit, invalid, pristine, reset }">
        <cv-modal
          :visible="quickActionsVisible"
          @modal-hidden="quickActionsVisible = false; reset()"
          @primary-click="handleSubmit(updateFarm(dataCopy))"
          :primary-button-disabled="invalid || pristine"
          has-form-content>
          <template v-slot:label>
            关键数据
          </template>
          <template v-slot:title>
            {{ dataCopy.name }}
          </template>
          <template v-slot:content>
            <validation-provider>
              <label class="bx--label">审核</label>
              <cv-radio-group>
                <cv-radio-button v-model="dataCopy.status" label="通过" value="admitted" />
                <cv-radio-button v-model="dataCopy.status" label="未通过" value="waitlisted" />
              </cv-radio-group>
            </validation-provider>
            <validation-provider>
              <cv-toggle
                v-model="dataCopy.assessment_participation"
                value="assessment_participation"
                label="参与对标">
                <template v-slot:text-left>不参与</template>
                <template v-slot:text-right>参与</template>
              </cv-toggle>
            </validation-provider>
            <validation-provider>
              <cv-toggle
                v-model="dataCopy.assessment_ready"
                value="assessment_ready"
                label="对标就绪">
                <template v-slot:text-left>未就绪</template>
                <template v-slot:text-right>就绪</template>
              </cv-toggle>
            </validation-provider>

            <validation-provider
              name="额定流量"
              rules="required|number"
              v-slot="{ errors }">
              <cv-number-input
                label="额定流量"
                v-model.number="dataCopy.rated_flow_rate">
                <template v-if="errors[0]" v-slot:invalid-message>
                  {{ errors[0] }}
                </template>
              </cv-number-input>
            </validation-provider>
            <validation-provider
              name="额定功率"
              rules="required|number"
              v-slot="{ errors }">
              <cv-number-input
                label="额定功率"
                v-model.number="dataCopy.rated_power">
                <template v-if="errors[0]" v-slot:invalid-message>
                  {{ errors[0] }}
                </template>
              </cv-number-input>
            </validation-provider>
            <validation-provider
              name="正常高水位"
              rules="required|number"
              v-slot="{ errors }">
              <cv-number-input
                label="正常高水位"
                v-model.number="dataCopy.design_normal_high_water_level">
                <template v-if="errors[0]" v-slot:invalid-message>
                  {{ errors[0] }}
                </template>
              </cv-number-input>
            </validation-provider>
            <validation-provider
              name="死水位"
              rules="required|number"
              v-slot="{ errors }">
              <cv-number-input
                label="死水位"
                v-model.number="dataCopy.design_dead_water_level">
                <template v-if="errors[0]" v-slot:invalid-message>
                  {{ errors[0] }}
                </template>
              </cv-number-input>
            </validation-provider>

            <validation-provider
              name="a值"
              rules="required|number"
              v-slot="{ errors }">
              <cv-number-input
                v-if="dataCopy.key_var"
                label="a值"
                v-model.number="dataCopy.key_var.native_power_coefficients.a">
                <template v-if="errors[0]" v-slot:invalid-message>
                  {{ errors[0] }}
                </template>
              </cv-number-input>
            </validation-provider>
            <validation-provider
              name="b值"
              rules="required|number"
              v-slot="{ errors }">
              <cv-number-input
                v-if="dataCopy.key_var"
                label="b值"
                v-model.number="dataCopy.key_var.native_power_coefficients.b">
                <template v-if="errors[0]" v-slot:invalid-message>
                  {{ errors[0] }}
                </template>
              </cv-number-input>
            </validation-provider>
            <validation-provider
              name="c值"
              rules="required|number"
              v-slot="{ errors }">
              <cv-number-input
                v-if="dataCopy.key_var"
                label="c值"
                v-model.number="dataCopy.key_var.native_power_coefficients.c">
                <template v-if="errors[0]" v-slot:invalid-message>
                  {{ errors[0] }}
                </template>
              </cv-number-input>
            </validation-provider>
          </template>
          <template v-slot:secondary-button>
            取消
          </template>
          <template v-slot:primary-button>
            更新
          </template>
        </cv-modal>
      </validation-observer>
    </wa-scope>
    <wa-scope ref="bindingScope" v-slot="{ data }">
      <validation-observer v-slot="{ reset }">
        <cv-modal
          :visible="bindingVisible"
          @modal-hidden="bindingVisible = false; reset()"
          has-form-content>
          <template v-slot:label>
            绑定用户
          </template>
          <template v-slot:title>
            {{ data.name }}
          </template>
          <template v-slot:content>
            <cv-multi-select
              ref="bindingMultiSelect"
              label="请输入关键词搜索，可多选"
              title="添加绑定用户"
              filterable
              @filter="searchUsers"
              @change="updateUsersToBind"
              :options="searchedUsers">
            </cv-multi-select>
            <cv-definition-tooltip
              alignment="start"
              direction="bottom"
              definition="用户名，真实姓名，电话，邮箱，工作单位。"
              term="可搜索范围包括..." />
            <cv-dropdown
              inline
              v-for="user in usersToBind"
              :key="user._id"
              :label="`${user.real_name} (${user.workplace})`"
              v-model="user.bindingAccess">
              <cv-dropdown-item
                v-for="binding in bindingAccesses"
                :key="binding.value"
                :value="binding.value">
                {{ binding.label }}
              </cv-dropdown-item>
            </cv-dropdown>
            <div class="bx--form-item">
              <cv-button
                size="sm"
                kind="primary"
                :disabled="usersToBind.length === 0"
                @click="bindUsersToFarm(data)">
                绑定
              </cv-button>
            </div>
            <hr class="wind--divider" />
            <legend class="bx--label">
              已绑定用户
            </legend>
            <cv-checkbox
              v-for="user in boundUsers"
              :key="user._id"
              :value="user.relationship._id"
              :label="`${selectionsValueToLabel( String(user.relationship.access), bindingAccesses )}: ${user.real_name} (${user.workplace})`"
              v-model="relationshipsToUnbind">
            </cv-checkbox>
            <div class="bx--form-item">
              <cv-button
                kind="danger"
                size="sm"
                :disabled="relationshipsToUnbind.length === 0"
                @click="unbindUsersFromFarm(data)">
                解绑
              </cv-button>
            </div>
          </template>
        </cv-modal>
      </validation-observer>
    </wa-scope>
  </div>
</template>
<script>
import { selections, numbers, texts, navigate } from '../mixins'
import { WaScope } from '../components'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  CvSearch,
  CvDefinitionTooltip,
  CvAccordion,
  CvAccordionItem,
  CvDropdown,
  CvDropdownItem,
  CvButton,
  CvDataTableSkeleton,
  CvDataTable,
  CvDataTableRow,
  CvDataTableCell,
  CvTag,
  CvOverflowMenu,
  CvOverflowMenuItem,
  CvPagination,
  CvModal,
  CvRadioGroup,
  CvRadioButton,
  CvToggle,
  CvNumberInput,
  CvCheckbox,
  CvMultiSelect
} from '@carbon/vue/src/index'
export default {
  name: 'FarmsSearch',
  mixins: [
    selections, 
    numbers,
    texts,
    navigate
  ],
  components: {
    WaScope,
    ValidationObserver,
    ValidationProvider,
    CvSearch,
    CvDefinitionTooltip,
    CvAccordion,
    CvAccordionItem,
    CvDropdown,
    CvDropdownItem,
    CvButton,
    CvDataTableSkeleton,
    CvDataTable,
    CvDataTableRow,
    CvDataTableCell,
    CvTag,
    CvOverflowMenu,
    CvOverflowMenuItem,
    CvPagination,
    CvModal,
    CvRadioGroup,
    CvRadioButton,
    CvToggle,
    CvNumberInput,
    CvCheckbox,
    CvMultiSelect
  },
  data: () => ({
    columns: [
      '水电场',
      '地区',
      '审核',
      '对标',
      '额定功率',
      '额定流量',
      '操作'
    ],
    farms: [],
    loading: false,
    rowExpanded: -1,
    limit: 10,
    skip: 0,
    keyword: '',
    page: 1,
    quickActionsVisible: false,
    bindingVisible: false,
    boundUsers: [],
    relationshipsToUnbind: [],
    searchedUsers: [],
    usersToBind: [],
    statusItems: [
      {
        label: '无限制',
        value: ''
      },
      {
        label: '通过',
        value: 'admitted'
      },
      {
        label: '未通过',
        value: 'waitlisted'
      }
    ],
    assessmentReadyItems: [
      {
        label: '无限制',
        value: ''
      },
      {
        label: '就绪',
        value: 'true'
      },
      {
        label: '未就绪',
        value: 'false'
      }
    ],
    assessmentParticipationItems: [
      {
        label: '无限制',
        value: ''
      },
      {
        label: '参与',
        value: 'true'
      },
      {
        label: '不参与',
        value: 'false'
      }
    ],
    farmTypeItems: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '坝体式',
        value: 'body'
      },
      {
        label: '坝后式',
        value: 'rear'
      },
      {
        label: '地下式',
        value: 'underground'
      },
      {
        label: '岸边式',
        value: 'shore'
      }
    ],
    turbineTypeItems: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '混流式',
        value: 'mix-flow'
      },
      {
        label: '轴流式',
        value: 'propeller-type'
      },
      {
        label: '冲击式',
        value: 'impulse'
      }
    ],
    performanceAdjustmentItems: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '日调节',
        value: 'daily'
      },
      {
        label: '周调节',
        value: 'weekly'
      },
      {
        label: '季调节',
        value: 'seasonal'
      },
      {
        label: '年调节',
        value: 'yearly'
      },
      {
        label: '多年调节',
        value: 'multi-yearly'
      }
    ],
    damTypeItems: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '混凝土重力坝',
        value: 'concrete_gravity_dam'
      },
      {
        label: '混凝土碾压重力垻',
        value: 'crushed_concrete_gravity_dam'
      },
      {
        label: '拱垻',
        value: 'arch_dam'
      },
      {
        label: '土石坝',
        value: 'stone_masonry_dam'
      },
      {
        label: '面板堆石坝',
        value: 'rockfill_dam'
      },
      {
        label: '闸坝',
        value: 'sluice_dam'
      }
    ],
    searchFilters: {
      status: '',
      assessment_ready: '',
      assessment_participation: '',
      farm_type: '',
      turbine_type: '',
      performance_adjustment: '',
      dam_type: '',
    }
  }),
  computed: {
    displayedFarms (){
      if (this.farms.length == this.limit + 1) {
        return this.farms.slice(0, this.farms.length - 1)
      }
      return this.farms
    },
    noMore (){
      return this.farms.length < this.limit + 1
    },
    searchParams (){
      let params = {
        limit: this.limit + 1,
        keyword: this.keyword,
        skip: this.skip
      }

      if (this.searchFilters.status !== '') {
        params.status = this.searchFilters.status
      }

      if (this.searchFilters.assessment_ready !== '') {
        params.assessment_ready = this.textsConvertToBoolean(this.searchFilters.assessment_ready)
      }

      if (this.searchFilters.assessment_participation !== '') {
        params.assessment_participation = this.textsConvertToBoolean(this.searchFilters.assessment_participation)
      }

      if (this.searchFilters.farm_type !== '') {
        params.farm_type = this.searchFilters.farm_type
      }

      if (this.searchFilters.turbine_type !== '') {
        params.turbine_type = this.searchFilters.turbine_type
      }

      if (this.searchFilters.dam_type !== '') {
        params.dam_type = this.searchFilters.dam_type
      }

      if (this.searchFilters.performance_adjustment !== '') {
        params.performance_adjustment = this.searchFilters.performance_adjustment
      }

      return params
    }
  },
  methods: {
    fetch (){
      this.loading = true
      this.$api.farm.search(this.searchParams).then( r => {
        if (r.ok) {
          this.farms = r.data.farms
        }
      } ).then( () => {
        this.loading = false
      })
    },
    search (){
      this.skip = 0
      this.page = 1
      this.fetch()
    },
    pageChange (e){
      this.skip = e.start - 1
      this.page = e.page
      this.limit = e.length
      this.fetch()
    },
    openQuickActions (farm){
      this.$refs.quickActionsScope.set(farm)
      this.quickActionsVisible = true
    },
    updateFarm (farm){
      this.$api.farm.update(farm).then( r => {
        if (r.ok) {
          this.$store.dispatch('ui/toast-notification/show', {
            title: '修改成功',
            caption: `${farm.name} 关键数据已修改。`,
            kind: 'success'
          })
        } else {
          this.$store.dispatch('ui/toast-notification/show', {
            title: '修改出错',
            caption: `${r.error.description}`,
            kind: 'warning'
          })
        }
        this.fetch()
      })
    },
    deleteFarm (farm){
      this.$store.dispatch('ui/modal/open', {
        title: '确认删除？',
        message: `确认后，${farm.name} 将会被永久删除。此风场与用户之间的绑定也将被删除。`,
        kind: 'danger',
        confirmText: '确认删除',
        cancelText: '取消',
        onConfirm: () => {
          this.$api.farm.delete(farm._id).then( r => {
            if (r.ok) {
              this.$store.dispatch('ui/toast-notification/show', {
                title: '删除成功',
                caption: `被删除风场：${farm.name}`,
                kind: 'success'
              })
              // Refresh searched results without changing search params
              this.fetch()
            } else {
              this.$store.dispatch('ui/toast-notification/show', {
                title: '删除失败',
                caption: r.error.description,
                kind: 'warning'
              })
            }
          })
        }
      })
    },
    openBinding (farm){
      this.$refs.bindingScope.set(farm)
      this.$refs.bindingMultiSelect.clearValues()
      this.$refs.bindingMultiSelect.clearFilter()
      this.bindingVisible = true
      this.boundUsers = []
      this.relationshipsToUnbind = []
      this.usersToBind = []

      this.$api.farm.relatedUsers(farm._id, 1).then( r => {
        if (r.ok) {
          r.data.ids.forEach( id => {
            this.$api.user.getUser(id.uid).then( r => {
              if (r.ok) {
                r.data.relationship = id
                this.boundUsers.push(r.data)
              }
            })
          })
        } else {
          console.log(r.error)
        }
      })
    },
    searchUsers (keyword){
      if (keyword === '') {
        this.searchedUsers = []
        return
      }
      this.$api.user.find({
        keyword: keyword
      }).then( r => {
        if (r.ok) {
          this.searchedUsers = r.data.users.map( user => {
            user.bindingAccess = '1'
            return {
              value: JSON.stringify(user),
              label: `${user.real_name} (${user.workplace})`,
              name: `${user.real_name} (${user.workplace})`
            }
          })
        } else {
          this.searchedUsers = []
        }
      })
    },
    updateUsersToBind (selected){
      this.usersToBind = selected.map( userJSONStr => JSON.parse(userJSONStr) )
    },
    bindUsersToFarm (farm){
      let bindingRequests = this.usersToBind.map( user => {
        return this.$api.farm.bindUser(farm._id, user._id, Number(user.bindingAccess)).then( r => {
          if (r.ok) {
            this.$store.dispatch('ui/toast-notification/show', {
              title: '绑定成功',
              kind: 'success',
              caption: `${user.real_name} 与 ${farm.name} 已绑定，权限为 ${this.selectionsValueToLabel(user.bindingAccess, this.bindingAccesses)}`
            })
          } else {
            this.$store.dispatch('ui/toast-notification/show', {
              title: '绑定出错',
              kind: 'warning',
              caption: `${r.error.description}`
            })
          }
        })
      })

      Promise.all(bindingRequests).then( () => {
        this.openBinding(farm)
      })
    },
    unbindUsersFromFarm (farm){
      let unbindingRequests = this.relationshipsToUnbind.map( rid => {
        return this.$api.farm.unbindUser(rid).then( r =>{
          if (r.ok) {
            this.$store.dispatch('ui/toast-notification/show', {
              title: '解绑成功',
              kind: 'success'
            })
          } else {
            this.$store.dispatch('ui/toast-notification/show', {
              title: '解绑失败',
              kind: 'warning',
              caption: `${r.error.description}`
            })
          }
        })
      })

      Promise.all(unbindingRequests).then( () => {
        this.openBinding(farm)
      } )
    }
  }
}
</script>